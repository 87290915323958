import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-center" }
const _hoisted_2 = { class: "d-flex align-center" }
const _hoisted_3 = { class: "font-weight-medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_tm_template = _resolveComponent("tm-template")!
  const _component_campaigns_sent_dropdown = _resolveComponent("campaigns-sent-dropdown")!
  const _component_tm_table = _resolveComponent("tm-table")!

  return (_openBlock(), _createBlock(_component_tm_table, { "align-top": "" }, {
    "body-cell-name-slot": _withCtx(({ row }) => [
      _createVNode(_component_router_link, {
        class: "d-flex align-center font-color-inherit blue-on-hover pointer font-weight-medium",
        to: { name: _ctx.type === 'sms' ? 'base.campaigns.sms.details' : 'base.campaigns.email.details' }
      }, {
        default: _withCtx(() => [
          _createVNode(_component_tm_icon, {
            name: _ctx.icon,
            class: "distinct--text mr-2"
          }, null, 8, ["name"]),
          _createElementVNode("span", null, _toDisplayString(row.name), 1)
        ]),
        _: 2
      }, 1032, ["to"])
    ]),
    "body-cell-recipients-slot": _withCtx(({ row }) => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_tm_icon, {
          name: "tmi-people",
          size: "xLarge",
          class: "primary--text mr-2"
        }),
        _createElementVNode("span", null, _toDisplayString(_ctx.formatNumber(row.recipients)), 1)
      ])
    ]),
    "body-cell-message-slot": _withCtx(({ row }) => [
      _createVNode(_component_tm_template, {
        content: row.content
      }, null, 8, ["content"])
    ]),
    "body-cell-delivered-slot": _withCtx(({ row }) => [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_tm_icon, {
          name: "check_circle_outline",
          class: "success--text mr-2",
          size: "xLarge"
        }),
        _createElementVNode("span", _hoisted_3, _toDisplayString(row.deliveryPercent) + "%", 1)
      ])
    ]),
    "body-cell-actions": _withCtx(({ row }) => [
      _createVNode(_component_campaigns_sent_dropdown, { item: row }, null, 8, ["item"])
    ]),
    _: 1
  }))
}