
import type { PropType } from 'vue'
import { defineComponent, ref } from 'vue'
import TopFilter from '@/components/layout/topFilter/TopFilter.vue'
import TopFilterButton from '@/components/layout/topFilter/TopFilterButton.vue'
import DropdownFilterRadio from '@/components/shared/filters/radio/DropdownFilterRadio.vue'
import DropdownFilterAssignee from '@/components/shared/filters/dropdown/DropdownFilterAssignee.vue'
import type { FiltersListType } from '@/definitions/shared/filters/types'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'CampaignsSentFilter',
  components: {
    TopFilterButton,
    TopFilter,
    DropdownFilterRadio,
    DropdownFilterAssignee,
  },
  props: {
    filters: {
      type: Object as PropType<FiltersListType>,
    },
  },
  emits: ['update:radio-value', 'update:model-value', 'reset'],
  setup() {
    const route = useRoute()
    const flowLink = ref(route.fullPath.includes('sms') ? 'base.campaigns.sms.flow' : 'base.campaigns.email.flow')
    return {
      flowLink,
    }
  },
})
