
import { defineComponent } from 'vue'
import TmTemplate from '@/components/shared/TmTemplate.vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import CampaignsSentDropdown from '@/components/pages/campaigns/sent/CampaignsSentDropdown.vue'
import { formatNumber } from '@/services/utils'

export default defineComponent({
  components: {
    TmTemplate,
    TmTable,
    CampaignsSentDropdown,
  },
  props: {
    icon: {
      type: String,
      default: 'chat',
    },
    type: {
      type: String,
      default: 'sms',
    },
  },
  setup() {
    return {
      formatNumber,
    }
  },
})
