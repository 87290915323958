import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_campaigns_sent_filter = _resolveComponent("campaigns-sent-filter")!
  const _component_campaigns_sent_table = _resolveComponent("campaigns-sent-table")!
  const _component_page_content = _resolveComponent("page-content")!

  return (_openBlock(), _createBlock(_component_page_content, null, {
    "filter-bar-dropdown": _withCtx(() => [
      _renderSlot(_ctx.$slots, "filter-bar-dropdown")
    ]),
    "filter-bar-slot": _withCtx(() => [
      _renderSlot(_ctx.$slots, "filter-bar-slot")
    ]),
    default: _withCtx(() => [
      _createVNode(_component_campaigns_sent_filter, {
        search: _ctx.search,
        "onUpdate:search": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
        columns: _ctx.tableHeaders,
        "onUpdate:columns": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.tableHeaders) = $event)),
        selected: _ctx.selected,
        filters: _ctx.filters,
        "changed-filters-count": _ctx.isDefault.changesCount,
        "search-placeholder": "Search campaigns",
        "onUpdate:radioValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.filters[$event.name].radioValue = $event.value)),
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.filters[$event.name].value = $event.value)),
        onReset: _ctx.reset
      }, null, 8, ["search", "columns", "selected", "filters", "changed-filters-count", "onReset"]),
      _createVNode(_component_campaigns_sent_table, {
        modelValue: _ctx.selected,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selected) = $event)),
        headers: _ctx.tableHeaders,
        items: _ctx.filteredTableItems,
        "item-key": "id",
        "items-name": "campaigns",
        "show-selection": "",
        "hide-phantom-col": "",
        type: "email",
        icon: "email"
      }, null, 8, ["modelValue", "headers", "items"])
    ]),
    _: 3
  }))
}