import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_top_filter_button = _resolveComponent("top-filter-button")!
  const _component_dropdown_filter_radio = _resolveComponent("dropdown-filter-radio")!
  const _component_dropdown_filter_assignee = _resolveComponent("dropdown-filter-assignee")!
  const _component_top_filter = _resolveComponent("top-filter")!

  return (_openBlock(), _createBlock(_component_top_filter, {
    "show-filters": "",
    "hide-columns-additional-buttons": "",
    "visible-buttons": 5,
    "columns-label": "Campaigns fields",
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:model-value', $event))),
    "onUpdate:radioValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:radio-value', $event))),
    onReset: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('reset')))
  }, {
    "filter-line-left-default": _withCtx(() => [
      _createVNode(_component_top_filter_button, {
        text: "New campaign",
        icon: "add",
        to: { name: _ctx.flowLink }
      }, null, 8, ["to"])
    ]),
    "filter-line-left-selected": _withCtx(() => [
      _createVNode(_component_top_filter_button, {
        text: "Delete",
        icon: "delete"
      })
    ]),
    "filter-line-details": _withCtx(({ updateRadioValue, updateModelValue }) => [
      _createVNode(_component_dropdown_filter_radio, _mergeProps({
        "model-value": _ctx.filters.sendDate.value
      }, _ctx.filters.sendDate, {
        name: "Send date",
        "onUpdate:radioValue": ($event: any) => (updateRadioValue('sendDate', $event)),
        "onUpdate:modelValue": ($event: any) => (updateModelValue('sendDate', $event))
      }), null, 16, ["model-value", "onUpdate:radioValue", "onUpdate:modelValue"]),
      _createVNode(_component_dropdown_filter_radio, _mergeProps({
        "model-value": _ctx.filters.tags.value
      }, _ctx.filters.tags, {
        name: "Tags",
        "onUpdate:radioValue": ($event: any) => (updateRadioValue('tags', $event)),
        "onUpdate:modelValue": ($event: any) => (updateModelValue('tags', $event))
      }), null, 16, ["model-value", "onUpdate:radioValue", "onUpdate:modelValue"]),
      _createVNode(_component_dropdown_filter_assignee, {
        modelValue: _ctx.filters.createdBy.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filters.createdBy.value) = $event)),
        options: _ctx.filters.createdBy.options,
        label: "Created by",
        "search-placeholder": "Search user"
      }, null, 8, ["modelValue", "options"])
    ]),
    _: 1
  }))
}